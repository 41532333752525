import ThinningTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/thinning/ThinningTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/Thinning/ThinningTemplate',
  component: ThinningTemplate
};

const createStory = props => () => ({
  components: { ThinningTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><thinning-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  thinningSkin: BOOLEAN.YES
});

export const unselected = createStory({
  thinningSkin: ''
});
